<template>
  <md-field>
    <label>文字色 : {{ inputValue }}</label>
    <photoshop-picker v-model="inputValue" />
  </md-field>
</template>

<script>
import { Photoshop } from "vue-color";

export default {
  name: "EditRecordTextcolor",
  components: {
    "photoshop-picker": Photoshop
  },
  props: { value: String },
  computed: {
    inputValue: {
      get() {
        if (this.value) {
          return this.value;
        } else {
          return "";
        }
      },
      set(value) {
        this.$emit("input", value.hex);
      }
    }
  }
};
</script>
