<template>
  <div class="ParamEditor">
    <md-card class="md-layout-item">
      <md-card-header>
        <button v-on:click="addRecord()">レコードを足す</button>
      </md-card-header>
      <md-card-content v-for="record in records.stamp" v-bind:key="record.id">
        <form novalidate class="md-layout">
          <md-card class="md-layout-item">
            <md-card-header>
              <div class="md-title">
                id {{ record.id }}
                <md-button v-on:click="deleteRecord(record.id)" md-icon-button md-accent>
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-card-header>
            <md-card-content>
              <md-field>
                <label>掲載箇所</label>
                <md-select v-model="record.position">
                  <md-option disabled value>Please select one</md-option>
                  <md-option value="1">1 : 映像見放題</md-option>
                  <md-option value="2">2 : 音楽聴き放題</md-option>
                  <md-option value="3">3 : 雑誌読み放題</md-option>
                  <md-option value="4">4 : おトク電子書籍</md-option>
                  <md-option value="5">5 : ライブ・イベント</md-option>
                  <md-option value="6">6 : ショッピング</md-option>
                  <md-option value="7">7 : auPAY</md-option>
                  <md-option value="8">8 : クーポン</md-option>
                  <md-option value="9">9 : アプリ</md-option>
                  <md-option value="10">10 : 5G</md-option>
                  <md-option value="11">11 : ポイント数表示</md-option>
                </md-select>
              </md-field>
              <md-field>
                <label>表示モード</label>
                <md-select v-model="record.mode">
                  <md-option disabled value>Please select one</md-option>
                  <md-option value="0">0: 表示なし</md-option>
                  <md-option value="1">1: アイコン上部中央</md-option>
                </md-select>
              </md-field>
              <md-field>
                <label>背景</label>
                <md-select v-model="record.background">
                  <md-option disabled value>Please select one</md-option>
                  <md-option value="rectangle_maru">rectangle_maru: 丸カド</md-option>
                  <md-option value="rectangle_kaku">rectangle_kaku : 角カド</md-option>
                </md-select>
              </md-field>
              <md-field>
                <label>文字スタイル</label>
                <md-select v-model="record.fontstyle">
                  <md-option disabled value>Please select one</md-option>
                  <md-option value="normal">normal : 通常</md-option>
                  <md-option value="bold">bold : 太文字</md-option>
                </md-select>
              </md-field>
              <md-field>
                <label>文言</label>
                <md-input v-model="record.text" />
              </md-field>
              <EditRecordTextcolor v-model="record.textColor" />
            </md-card-content>
          </md-card>
        </form>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { Photoshop } from "vue-color";
import Datetime from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

import EditRecordTextcolor from "./EditRecordTextcolor.vue";

export default {
  name: "ParamEditor",
  components: {
    EditRecordTextcolor,
    "photoshop-picker": Photoshop,
    Datetime
  },
  props: { jsondata: Object },
  data() {
    return {
      records: { stamp: [] }
    };
  },
  created: function() {
    this.$parent.jsondata = this.records;
  },
  methods: {
    addRecord: function() {
      let maxId = 0;
      this.records.stamp.forEach(record => {
        if (maxId < record.id) {
          maxId = record.id;
        }
      });
      this.records.stamp.push({
        id: maxId + 1,
        position: "1",
        mode: "0",
        background: "",
        fontstyle: "",
        text: "",
        textColor: "#FFFFFF"
        //        startDateTime: "",
        //        endDateTime: "",
      });
    },
    deleteRecord: function(targetId) {
      let targetIndex;
      for (let i = 0; i < this.records.stamp.length; i++) {
        let record = this.records.stamp[i];
        if (record.id == targetId) {
          targetIndex = i;
        }
      }
      this.records.stamp.splice(targetIndex, 1);
    }
  }
};
</script>

<style scoped></style>
