<template>
  <div class="rootview">
    <div class="header">
      <h1>Repro UXオプティマイザに投入する吹き出し定義データJSONを作るやつ</h1>
    </div>
    <div class="wrapper md-layout md-gutter md-alignment-top">
      <div class="md-layout-item md-medium-size-75 md-small-size-75 md-xsmall-size-100">
        <ParamEditor :jsondata="jsondata" />
      </div>
      <div class="md-layout-item md-medium-size-25 md-small-size-25 md-xsmall-size-100">
        <JsonView :jsondata="JSON.stringify(jsondata, null, 2)" />
      </div>
    </div>
  </div>
</template>

<script>
import ParamEditor from "./ParamEditor";
import JsonView from "./JsonView";

export default {
  name: "MainView",
  components: {
    ParamEditor,
    JsonView
  },
  data() {
    return {
      jsondata: {}
    };
  }
};
</script>

<style scoped>
.wrapper {
  text-align: left;
}
</style>
