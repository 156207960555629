<template>
  <div class="JsonViewer wrapper md-layout md-gutter md-alignment-top-left">
    <md-card class="md-layout-item">
      <md-field>
        <label>生成されたJSON</label>
        <md-icon>description</md-icon>
        <md-textarea v-model="jsondata" md-autogrow md-counter="50000"></md-textarea>
      </md-field>

      <!--
        <md-field>
        <label>生成されたJSONをXMLに変更してみたもの</label>
        <md-textarea v-model="xmldata" md-autogrow></md-textarea>
        <md-icon>description</md-icon>
      </md-field>
      -->
    </md-card>
  </div>
</template>

<script>
import * as xmljs from "xml-js";

export default {
  name: "JsonView",
  props: {
    jsondata: {
      type: String,
      required: true
    }
  },
  computed: {
    xmldata() {
      return xmljs.json2xml(JSON.parse(this.jsondata), {
        compact: true,
        spaces: 2
      });
    }
  }
};
</script>
<style scoped></style>
